<template>
  <div @touchmove.prevent>
    <scroll ref="scroll" class="scroll" :data="data">
      <div>
        <search-content></search-content>
      </div>
    </scroll>
  </div>
</template>

<script>
import Scroll from '@/components/scroll/scroll'
import SearchContent from './components/SearchContent'

export default {
  name: 'Search',
  components: {
    Scroll,
    SearchContent
  },
  activated () {
    setTimeout(() => {
      this.$refs.scroll.refresh()
    }, 100)
  },
  data () {
    return {
      data: []
    }
  }
}
</script>

<style scoped lang="stylus">
.scroll
  position absolute
  top 0
  left 0
  right 0
  bottom 0
  overflow hidden
  background rgba(255, 255, 255, 1)
</style>
