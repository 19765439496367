<template>
  <div class="wrapper">
    <div class="header">
      <form class="search-inp-block" action="" @submit.prevent="onSubmit" ref="searchForm">
        <div class="search-pic"></div>
        <input type="text" class="search-inp" v-model="searchInp" autocomplete="off">
        <div
          class="destroy"
          v-show="searchInp.length"
          @click="searchInp=''"
        ></div>
      </form>
      <div class="back" @click="$router.push('/')">
        <div class="back-content">取消</div>
      </div>
    </div>
    <div class="search-history" v-if="searchList.length">
      <div class="search-history-title">
        <div class="title-content">搜索历史</div>
        <div class="search-history-destroy" @click="setSearchList([])"></div>
      </div>
      <div class="history-list">
        <div class="history-item" v-for="(item,index) in searchList" :key="index" @click="selectItem(item)">
          <div class="history-item-content">{{ item }}</div>
        </div>
      </div>
    </div>
    <div class="search-history mrt">
      <div class="search-history-title">
        <div class="title-content">热门搜索</div>
      </div>
      <div class="history-list">
        <div class="history-item" @click="selectItem('党员手册')">
          <div class="history-item-icon"></div>
          <div class="history-item-content">党员手册</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'SearchContent',
  activated () {
    this.searchInp = ''
  },
  computed: {
    ...mapGetters([
      'searchList'
    ])
  },
  data () {
    return {
      searchInp: ''
    }
  },
  methods: {
    onSubmit () {
      let arr = this.searchList
      arr.unshift(this.searchInp)
      if (arr.length > 10) {
        arr.splice(10, arr.length - 10)
      }
      this.setSearchList(arr)
      this.$router.push({
        name: 'SearchRes',
        params: {
          keywords: this.searchInp
        }
      })
      return false
    },
    selectItem (item) {
      this.$router.push({
        name: 'SearchRes',
        params: {
          keywords: item
        }
      })
    },
    ...mapMutations({
      setSearchList: 'SET_SEARCH_LIST'
    })
  }
}
</script>

<style scoped lang="stylus">
@import "~@/assets/stylus/mixin"
.wrapper
  width 750px

  .header
    display flex
    align-items center
    width 720px
    padding-left 30px
    height 114px

    .search-inp-block
      display flex
      align-items center
      width 568px
      padding 0 26px
      height 60px
      border-radius 30px
      overflow hidden
      background rgba(245, 245, 244, 1)

      .search-pic
        width 20px
        height 20px
        bg-image('~@/assets/img/search/search')
        background-size 100% 100%
        background-repeat no-repeat

      .search-inp
        width 498px
        padding-left 20px
        height 60px
        border none
        -webkit-appearance none
        border-radius 0
        font-size 30px
        color rgba(51, 51, 51, 1)
        background rgba(245, 245, 244, 1)

      .destroy
        width 30px
        height 30px
        bg-image('~@/assets/img/login/destory')
        background-size 100% 100%
        background-repeat no-repeat

    .back
      display flex
      align-items center
      width 100px
      height 60px

      .back-content
        width 80px
        padding-left 20px
        line-height normal
        font-size 26px
        color rgba(102, 102, 102, 1)

  .search-history
    display flex
    justify-content center
    flex-wrap wrap
    width 750px
    padding-bottom 24px
    margin-top 16px

    &.mrt
      padding-top 54px
      border-top 10px solid rgba(245, 245, 244, 1)

    .search-history-title
      display flex
      align-items center
      width 722px
      padding-left 20px
      height 30px
      border-left 8px solid rgba(179, 53, 58, 1)

      .title-content
        width 664px
        line-height normal
        font-size 30px
        font-weight bold
        color rgba(51, 51, 51, 1)

      .search-history-destroy
        width 24px
        height 24px
        bg-image('~@/assets/img/search/destroy')
        background-size 100% 100%
        background-repeat no-repeat

    .history-list
      display flex
      flex-wrap wrap
      width 690px
      margin-top 34px

      .history-item
        display flex
        align-items center
        height 50px
        padding 0 30px
        margin-right 20px
        margin-bottom 26px
        border-radius 25px
        background rgba(245, 245, 244, 1)

        .history-item-icon
          width 22px
          height 26px
          margin-right 8px
          bg-image('~@/assets/img/search/good')
          background-size 100% 100%
          background-repeat no-repeat

        .history-item-content
          line-height normal
          font-size 26px
          color rgba(51, 51, 51, 1)
</style>
